import React, { useEffect, useState, useRef } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { loader as gqlLoader } from 'graphql.macro';
import { Pagination } from '@material-ui/lab';
import GraphQLData from '../../lib/GraphQLData';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

interface myBlogResults {
  results: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: number;
      endCursor: number;
    };
    items: [blogsResult];
  };
}

interface SearchVariables {
  blogsUserQuery: string;
  blogsStartIndex: string;
}

type BlogListingProps = {
  rendering: {
    fields: {
      'Listing Header': {
        value: string;
      };
    };
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: {
      targetHostName: string;
    };
  };
  pinnedBlogsQuery: {
    blogResults: myBlogResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };
  otherBlogsQuery: {
    blogResults: myBlogResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };
};

interface blogsResult {
  datePublished: string;
  headline: string;
  blogPostCategory: string;
  blogPostSummary: string;
  link: string;
  item: {
    url: string;
    oGImage: {
      src: string;
      alt: string;
    };
    schemaImage: {
      src: string;
      alt: string;
    };
    contentCategory: {
      targetItem: {
        categoryLabel: {
          value: string;
        };
      };
    };
  };
}

interface BlogResultsQueryType {
  blogResults: {
    results: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: number;
        endCursor: number;
      };
      items: blogsResult[] | [];
    };
  };
  variables: SearchVariables;
  loading: boolean;
  error: {
    message: string;
    stack: string;
  };
}

// BLOG CARD LAYER
const BlogCard = ({
  blog,
  sitecoreContext,
  rendering,
}: {
  blog: blogsResult;
  sitecoreContext: any;
  rendering: any;
}): JSX.Element => {
  let ogImage = blog?.item?.oGImage;
  let schemaImage = blog?.item?.schemaImage;
  let fullpath = sitecoreContext.custom.targetHostName;
  fullpath = fullpath.replace('http://', 'https://');
  let calltoAction = rendering?.fields?.['Call to Action Text'].value;
  let categoryLabel = blog?.item?.contentCategory?.targetItem?.categoryLabel?.value;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString(sitecoreContext.language, { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  return (
    <div className="blog-category-card">
      <Card className="blog-card">
        <Grid container spacing={2}>
          {/* Image Section */}
          <Grid item xs={12} sm={4} className="blog-card-image-wrapper">
            <CardActionArea className="imagecard-aa" href={blog?.item?.url}>
              <CardMedia
                className="imagecard-image"
                component="img"
                style={{ height: '100%' }}
                image={
                  ogImage?.src ||
                  schemaImage?.src ||
                  rendering?.fields?.['Placeholder Image'].value?.src
                }
                title={ogImage?.alt || schemaImage?.alt || 'Placeholder Image'}
              />
            </CardActionArea>
          </Grid>

          {/* Content Section */}
          <Grid item xs={12} sm={8} className="blog-card-content-wrapper">
            <CardContent className="blog-card-content">
              {categoryLabel && (
                <Typography className="blog-category" component="span">
                  {categoryLabel}
                </Typography>
              )}
              {blog?.headline && (
                <a className="blogcard-heading-link" href={blog?.item?.url}>
                  <Typography className="blogcard-heading" component="span">
                    {blog?.headline}
                  </Typography>
                </a>
              )}
              {blog?.datePublished && (
                <Typography className="blogcard-publishing-date" component="span">
                  {formatDate(blog?.datePublished)}
                </Typography>
              )}
            </CardContent>
            <Typography className="blog-post-sumary">{blog?.blogPostSummary}</Typography>
            {blog?.item?.url && calltoAction && (
              <CardActions className="blogcard-link-container">
                <a className="blogcard-link" href={blog?.item?.url}>
                  {calltoAction}
                </a>
              </CardActions>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const BlogCategoryListing = ({
  pinnedBlogsQuery,
  otherBlogsQuery,
  sitecoreContext,
  rendering,
}: BlogListingProps): JSX.Element => {
  const heading = rendering?.fields?.['Listing Header']?.value;

  // In Sitecore page editing mode, show a preview message.
  if (sitecoreContext.pageEditing) {
    if (!rendering?.fields) {
      return <p>Please setup the Datasource.</p>;
    }
    return (
      <p>
        The blog listing content will appear here. To preview how it will look, please check the
        Preview mode. Everything is working as expected!
      </p>
    );
  }

  // Validate query availability.
  if (!otherBlogsQuery || !otherBlogsQuery.blogResults) {
    return sitecoreContext.pageEditing ? <p>Please setup the Datasource.</p> : <br />;
  }

  const { blogResults } = otherBlogsQuery;
  let pinnedItems = pinnedBlogsQuery?.blogResults?.results?.items ?? [];
  const otherItems = blogResults.results?.items ?? [];
  // Use the GraphQL variable blogsStartIndex (converted to a number) using radix 10.
  const pageSize = 5;
  const startIndex = parseInt(otherBlogsQuery.variables?.blogsStartIndex, 10) || 0;
  const currentPage = Math.floor((startIndex + pinnedItems.length) / pageSize) + 1;
  const updatedItems = currentPage === 1 ? [...pinnedItems, ...otherItems] : [...otherItems];

  // --- Pagination Logic ---

  const finalItems = updatedItems.slice(0, pageSize);
  const totalCount = blogResults.results.totalCount + pinnedItems.length;
  const pages = totalCount > 0 ? Math.ceil(totalCount / pageSize) : 1;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (typeof window !== 'undefined') {
      let newStartIndex = (value - 1) * pageSize;

      // Adjust start index if there are pinned items
      if (pinnedItems.length > 0) {
        newStartIndex = Math.max(newStartIndex - pinnedItems.length, 0);
      }

      // Build a new URL using the blogsUserQuery and blogsStartIndex variables.
      const queryUrl = `${sitecoreContext.custom.targetHostName}?q=${otherBlogsQuery.variables.blogsUserQuery}&si=${newStartIndex}`;
      window.open(queryUrl, '_self');
      event.preventDefault();
    }
  };
  return (
    <div className="blog-category-listing-wrapper">
      <div>
        <span className="blog-listing-header">{heading}</span>
      </div>

      <Grid className="blog-category-listing">
        {finalItems.map((blog: any) => (
          <BlogCard
            key={blog.link}
            blog={blog}
            sitecoreContext={sitecoreContext}
            rendering={rendering}
          />
        ))}
      </Grid>

      {pages > 1 && (
        <Pagination
          className="blog-category-pagination"
          count={pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

function getUserQuery() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let query = params?.get('q') || '';
  return query;
}

function getStartItem() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let start = params?.get('si') || '0';
  return start;
}

function getRootItem(props: any) {
  let rootItemPath = props.fields['Results Root Item']?.value;
  if (rootItemPath) {
    return rootItemPath;
  } else {
    return '/sitecore/content/UPMC/Italy/Home/blog';
  }
}

function getCategory(props: any) {
  let category = props?.fields?.['Content Category']?.id;
  if (category) {
    category = category.replace(/-/g, '');
    return category;
  } else {
    return null;
  }
}

function getPinnedListItems(props: any) {
  let pinnedBlogs = '';
  if (props) {
    if (props?.fields['Pinned Blog 1']) {
      const pinnedBlog1Id = props?.fields['Pinned Blog 1']?.id.replace(/-/g, '');
      if (props?.fields['Pinned Blog 2']) {
        const pinnedBlog2Id = props?.fields['Pinned Blog 2']?.id.replace(/-/g, '');
        pinnedBlogs = `in:${pinnedBlog1Id},${pinnedBlog2Id}`;
        return pinnedBlogs;
      } else {
        pinnedBlogs = `in:${pinnedBlog1Id}`;
        return pinnedBlogs;
      }
    } else {
      return 'in:00000000000000000000000000000000';
    }
  } else {
    return 'in:00000000000000000000000000000000';
  }
}

function getOtherBlogItems(props: any) {
  let otherBlogs = '';
  if (props) {
    if (props?.fields['Pinned Blog 1']) {
      const pinnedBlog1Id = props?.fields['Pinned Blog 1']?.id.replace(/-/g, '');
      if (props?.fields['Pinned Blog 2']) {
        const pinnedBlog2Id = props?.fields['Pinned Blog 2']?.id.replace(/-/g, '');
        otherBlogs = `notin:${pinnedBlog1Id},${pinnedBlog2Id}`;

        return otherBlogs;
      } else {
        otherBlogs = `notin:${pinnedBlog1Id}`;
        return otherBlogs;
      }
    } else {
      return 'notin:00000000000000000000000000000000';
    }
  } else {
    return 'notin:00000000000000000000000000000000';
  }
}

const BlogResultsQuery = gqlLoader('./query.graphql');

const MakeCombinedBlogQuery = (props: any) => {
  const PinnedBlogs = GraphQLData(BlogResultsQuery, {
    name: 'pinnedBlogsQuery',
    refetch: { getUserQuery },
    options: {
      variables: {
        blogsUserQuery: getUserQuery(),
        blogsRootItem: getRootItem(props),
        blogsStartIndex: '0', // Always start pinned blogs from the beginning
        pinnedList: getPinnedListItems(props),
        category: getCategory(props),
      },
      fetchPolicy: 'no-cache',
    },
  });

  const OtherBlogs = GraphQLData(BlogResultsQuery, {
    name: 'otherBlogsQuery',
    refetch: { getUserQuery },
    options: {
      variables: {
        blogsUserQuery: getUserQuery(),
        blogsRootItem: getRootItem(props),
        blogsStartIndex: getStartItem(),
        pinnedList: getOtherBlogItems(props),
        category: getCategory(props),
      },
      fetchPolicy: 'no-cache',
    },
  });

  // Run both queries in parallel while correctly passing props (including sitecoreContext)
  const WrappedComponent = (queryProps: any) => {
    return (
      <BlogCategoryListing
        {...props} // Pass the original props to retain sitecoreContext.
        {...queryProps} // Pass the GraphQL query results
      />
    );
  };

  // Apply both GraphQL HOCs separately and then render the final component
  return PinnedBlogs(OtherBlogs(WrappedComponent))(props);
};

export default MakeCombinedBlogQuery;
