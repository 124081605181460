import React, { useEffect, useState, useRef } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './index.scss';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

interface myBlogResults {
  results: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: number;
      endCursor: number;
    };
    items: [blogsResult];
  };
}

interface SearchVariables {
  blogsUserQuery: string;
}

type BlogListingProps = {
  rendering: {
    fields: {
      'Listing Header': {
        value: string;
      };
    };
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: {
      targetHostName: string;
    };
  };
  pinnedBlogsQuery: {
    blogResults: myBlogResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };
  otherBlogsQuery: {
    blogResults: myBlogResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };
};

interface blogsResult {
  datePublished: string;
  headline: string;
  link: string;
  item: {
    url: string;
    oGImage: {
      src: string;
      alt: string;
    };
    schemaImage: {
      src: string;
      alt: string;
    };
    contentCategory: {
      targetItem: {
        categoryLabel: {
          value: string;
        };
      };
    };
  };
}

interface BlogResultsQueryType {
  blogResults: {
    results: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: number;
        endCursor: number;
      };
      items: blogsResult[];
    };
  };
  variables: SearchVariables;
  loading: boolean;
  error: {
    message: string;
    stack: string;
  };
}

// BLOG CARD LAYER
const BlogCard = ({
  blog,
  sitecoreContext,
  rendering,
}: {
  blog: blogsResult;
  sitecoreContext: any;
  rendering: any;
}): JSX.Element => {
  let ogImage = blog?.item?.oGImage;
  let schemaImage = blog?.item?.schemaImage;
  let fullpath = sitecoreContext.custom.targetHostName;
  fullpath = fullpath.replace('http://', 'https://');
  let calltoAction = rendering?.fields?.['Call to Action Text'].value;
  let categoryLabel = blog?.item?.contentCategory?.targetItem?.categoryLabel?.value;
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString(sitecoreContext.language, { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  return (
    <div className="blogcard">
      <Card>
        <CardActionArea className="imagecard-aa" href={blog?.item?.url}>
          {ogImage.src ? (
            <CardMedia className="imagecard-image" image={ogImage?.src} title={ogImage?.alt} />
          ) : schemaImage.src ? (
            <CardMedia
              className="imagecard-image"
              image={schemaImage?.src}
              title={schemaImage?.alt}
            />
          ) : (
            <CardMedia
              className="imagecard-image"
              image={rendering?.fields?.['Placeholder Image'].value?.src}
              title="Placeholder Image"
            />
          )}
        </CardActionArea>
        <CardContent className="imagecard-header-wrapper">
          {categoryLabel ? (
            <Typography className="blog-category" component="span">
              {categoryLabel}
            </Typography>
          ) : null}
          {blog?.headline ? (
            <Typography className="blogcard-heading" component="span">
              {blog?.headline}
            </Typography>
          ) : null}
          {blog?.datePublished ? (
            <Typography className="blogcard-publishing-date" component="span">
              {formatDate(blog?.datePublished)}
            </Typography>
          ) : null}
        </CardContent>
        {blog?.item?.url && calltoAction ? (
          <CardActions className="blogcard-link-container">
            <a className="blogcard-link" href={blog?.item?.url}>
              {calltoAction}
            </a>
          </CardActions>
        ) : null}
      </Card>
    </div>
  );
};

// BLOG LIST LAYER
const BlogListing = ({
  pinnedBlogsQuery,
  otherBlogsQuery,
  sitecoreContext,
  rendering,
}: BlogListingProps): JSX.Element => {
  let heading = rendering?.fields?.['Listing Header']?.value;
  let otherBlogResults: BlogResultsQueryType = { ...otherBlogsQuery };
  const pinnedItems = pinnedBlogsQuery?.blogResults?.results?.items ?? [];
  const otherItems = otherBlogsQuery?.blogResults?.results?.items ?? [];
  const updatedItems = [...pinnedItems, ...otherItems];

  if (sitecoreContext.pageEditing) {
    if (!rendering?.fields) {
      return <p>Please setup the Datasource.</p>;
    } else {
      return (
        <p>
          The blog listing content will appear here. To preview how it will look, please check the
          Preview mode. Everything is working as expected!
        </p>
      );
    }
  }

  if (otherBlogResults && otherBlogResults.blogResults) {
    const { blogResults, loading, error } = otherBlogResults;

    if (pinnedItems.length > 0) {
      blogResults.results.items = updatedItems.slice(0, -pinnedItems.length);
    }

    // Validation & Error Handling
    if (!otherBlogsQuery) {
      if (sitecoreContext.pageEditing) {
        return <p>Please setup the Datasource.</p>;
      } else {
        return (
          <>
            <br />
          </>
        );
      }
    }

    return (
      <div className="blog-listing-wrapper">
        {blogResults && (
          <>
            <div>
              <span className="blog-listing-header">{heading}</span>
            </div>

            <Grid className="blog-listing">
              {blogResults.results.items.map((blog: blogsResult) => (
                <BlogCard
                  key={blog.link}
                  blog={blog}
                  sitecoreContext={sitecoreContext}
                  rendering={rendering}
                />
              ))}
            </Grid>
          </>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

function getUserQuery() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let query = params?.get('q') || '';
  return query;
}

function getStartItem() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let start = params?.get('si') || '0';
  return start;
}

function getRootItem(props: any) {
  let rootItemPath = props.fields['Results Root Item']?.value;
  if (rootItemPath) {
    return rootItemPath;
  } else {
    return '/sitecore/content/UPMC/Italy/Home/blog';
  }
}

function getPinnedListItems(props: any) {
  let pinnedBlogs = '';
  if (props) {
    if (props?.fields['Pinned Blog 1']) {
      const pinnedBlog1Id = props?.fields['Pinned Blog 1']?.id.replace(/-/g, '');
      if (props?.fields['Pinned Blog 2']) {
        const pinnedBlog2Id = props?.fields['Pinned Blog 2']?.id.replace(/-/g, '');
        pinnedBlogs = `in:${pinnedBlog1Id},${pinnedBlog2Id}`;
        return pinnedBlogs;
      } else {
        pinnedBlogs = `in:${pinnedBlog1Id}`;
        return pinnedBlogs;
      }
    } else {
      return 'in:00000000000000000000000000000000';
    }
  } else {
    return 'in:00000000000000000000000000000000';
  }
}

function getOtherBlogItems(props: any) {
  let otherBlogs = '';
  if (props) {
    if (props?.fields['Pinned Blog 1']) {
      const pinnedBlog1Id = props?.fields['Pinned Blog 1']?.id.replace(/-/g, '');
      if (props?.fields['Pinned Blog 2']) {
        const pinnedBlog2Id = props?.fields['Pinned Blog 2']?.id.replace(/-/g, '');
        otherBlogs = `notin:${pinnedBlog1Id},${pinnedBlog2Id}`;

        return otherBlogs;
      } else {
        otherBlogs = `notin:${pinnedBlog1Id}`;
        return otherBlogs;
      }
    } else {
      return 'notin:00000000000000000000000000000000';
    }
  } else {
    return 'notin:00000000000000000000000000000000';
  }
}

const BlogResultsQuery = gqlLoader('./query.graphql');

const MakeCombinedBlogQuery = (props: any) => {
  const PinnedBlogs = GraphQLData(BlogResultsQuery, {
    name: 'pinnedBlogsQuery',
    refetch: { getUserQuery },
    options: {
      variables: {
        blogsUserQuery: getUserQuery(),
        blogsRootItem: getRootItem(props),
        blogsStartIndex: '0', // Always start pinned blogs from the beginning
        pinnedList: getPinnedListItems(props),
      },
      fetchPolicy: 'no-cache',
    },
  });

  const OtherBlogs = GraphQLData(BlogResultsQuery, {
    name: 'otherBlogsQuery',
    refetch: { getUserQuery },
    options: {
      variables: {
        blogsUserQuery: getUserQuery(),
        blogsRootItem: getRootItem(props),
        blogsStartIndex: getStartItem(),
        pinnedList: getOtherBlogItems(props),
      },
      fetchPolicy: 'no-cache',
    },
  });

  // Run both queries in parallel while correctly passing props (including sitecoreContext)
  const WrappedComponent = (queryProps: any) => {
    return (
      <BlogListing
        {...props} // Pass the original props to retain sitecoreContext.
        {...queryProps} // Pass the GraphQL query results
      />
    );
  };

  // Apply both GraphQL HOCs separately and then render the final component
  return PinnedBlogs(OtherBlogs(WrappedComponent))(props);
};

export default MakeCombinedBlogQuery;
